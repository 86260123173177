.SiderTrigger{
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
    margin-top: 20px;
    margin-bottom: 0;
}
.SiderTrigger .btn{
    padding: 4px 14px!important;
}
.SiderTrigger .btn span{
    padding: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SiderTrigger .btn:hover{
    color: #fff!important
}
.sidebar-animation-enter {
    opacity: 0;
  }
  
  .sidebar-animation-enter-active {
    opacity: 1;
    transition: opacity 100ms;
  }
  
  .sidebar-animation-exit {
    opacity: 1;
  }
  
  .sidebar-animation-exit-active {
    opacity: 0;
    transition: opacity 100ms;
  }
  .SidebarMenuNew{
    overflow: hidden;
  }
  .sidebarmenu{
    padding-left: 20px;
    padding-right: 20px;
}
.submenu_morebtn{
    background-color: transparent;
    border: none;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-radius: 5px;
}
.submenu_morebtn:hover{
    background-color: rgba(0,0,0,.1);
    border: none;
}
.sidebar_parentmenu-list{
    list-style-type: none;
    padding: 0;
    margin: 8px 0 8px;
}
.sidebar_parentmenu-list li{
    cursor: pointer;
    padding: 4px 10px;
}
.sidebar_parentmenu-list li:hover{
    cursor: pointer;
    background-color: rgba(0,0,0,.04);
    padding: 4px 10px;
    border-radius: 3px;
   
}
.sidebar_parentmenu-list span{
    padding-left: 10px;
}


.SidebarProjects {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
}

.SidebarProjectsTitle {
    /* border-radius: 10px; */
    font-size: 16px;
    padding: 10px 10px;
    color: #555;
    display: flex;
    justify-content: space-between;
}

.SidebarProjectsTitle.active {
    background-color: #e6f4ff;
    color: #1677ff;
}

.SidebarProjectsAddTable {
    background-color: transparent;
    border: none;
    color: #1677ff;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.SidebarProjectsTableList {
    margin: 0;
    padding: 0;

    list-style-type: none;
}

.SidebarProjectsTableListItem {
    color: #000;
    cursor: pointer;
    padding: 10px 10px;
    padding-left: 20px;
}

.SidebarProjectsTableListItem:hover,
.SidebarProjectsTableListItem.active {
    background-color: #e6f4ff;
    color: #1677ff;
}

.submenu_title{
    text-overflow: ellipsis;
    overflow: hidden;
}