.FeedDashboardStructureshowTags .ant-checkbox-group{
    display: flex;
    flex-direction: column;
    gap: 6px;
 }
 .FeedDashboardStructureshowTags_title{
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 500;
 }
 .FeedDashboardStructureshowTags{
    margin-left: 47px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
 }
 .FeedDashboardStructureshowTagsTitle{
    margin-top: 30px;
    display: block;
 }
 .FeedDashboardStructure .ant-table-row td.ant-table-cell.ant-table-cell-ellipsis{
    padding-left: 10px!important;
 }
 .feeddashboardcard{
    border: 1px solid #D9D9D9;
    padding: 20px 35px;
    border-radius: 3px;
    height: 100%;
}
.feeddashboardcard-title{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 22px;
}
.feeddashboardcard-title.feeddashboardcard-title-smallgap{
    padding-bottom: 10px;
}

.feeddashboardcard-title a{
    font-size: 16px;
    font-weight: normal;
    text-decoration: underline;
    color: #11142F;
    display: block;
    margin-top: 5px;
}
.feeddashboardcard-title a:hover{

   color: #4A5EFF;
}
.feeddashboardcard-content_time{
    color: #7A7A7A;
    font-size: 20px;
    font-weight: normal;
}
/* .feeddashboardcard-content_statistic{
    color: #4A5EFF;
    font-size: 25px;
    font-weight: 600;
} */
.feeddashboardcard-content_statistic{
    color: #000;
    font-size: 14px;
    font-weight: 400;
}
.feeddashboardcard-content .ant-statistic-content-value{
    color: #4A5EFF;
}
.feeddashboardcard-content .ant-statistic-content-suffix{
    font-size: 14px;
}
.feeddashboardtop{
    margin-bottom: 30px;
}
.feeddashboardtop-item{
    margin-bottom: 10px;
    max-width: 600px;
}

.DashboardMenu{
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: 23px;
    justify-content: flex-end;
  }
  .DashboardMenu li.active a{
    color: #4A5EFF
  }
  .DashboardMenu li a{
    color: #7A7A7A;
    font-size: 16px;
    font-weight: 500;
  }
  .dashboardcontent_category-title{
    display: block;
    width: 300px;
  }
  .dashboardcontent{
    .ant-table-cell.ant-table-cell-with-append{
        display: flex;
        .ant-table-row-expand-icon{
            flex-shrink: 0;
        }
    }
  }

  .collapse-section-breadcrumb {
    background-color: #ffffff;
    padding: 16px;
  }
  .collapse-section {
    background-color: #ffffff;
    padding: 16px;
    margin-top: 40px;
  }
  .collapse-section .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .collapse-section .header a {
    color: #7E8299;
  }
