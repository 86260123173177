.loginform_passwordfield {
    /* position: relative; */
    text-align: right;
    margin-top: -10px;
    margin-bottom: 10px;
}
.authcard {
    width: 480px;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 48px;
    font-family: Manrope, Roboto, sans-serif;
}
.loginform-regtext {
    text-align: center;
    font-size: 14px;
}
.authcard h2 {
    text-align: center;
    font-weight: 600;
}
.addNewFeedBtn {
    text-decoration: underline;
    color: #A8A8A8;
    font-size: 13px;
    font-weight: 400;
}
.addNewFeedBtn span {
    padding-right: 2px;
}
.AddProjectTableFileDraggerIcon {
    margin: 0;
}
.tableimgthumb {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ececec;
    /* margin-right: 4px; */
}
.tableimgthumbWrapper {
    display: flex;
    gap: 10px;
    overflow: auto;
    max-width: 100%;
    margin: 10px 10px 5px 10px;
    padding-bottom: 5px;
    /* justify-content: center; */
}
.mainPageNoProjects {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #181C32;
    font-size: 16px;
    padding: 20px;
}
.filtertable_row{
    margin-bottom: 20px;
}
.error{
    color: #fd0140;
}