input.ant-input,
.ant-input-password {
    padding: 12px 16px;
}

input.ant-input.input-small {
    padding: 6px 16px;
}

.ant-select {
    height: 48px !important;
}

.ant-select.select-small {
    height: 36px !important;
}

.ant-select.ant-tree-select.input-small {
    height: auto !important;
    min-height: 36px !important;
}

.ant-select.ant-tree-select.input-small .ant-select-selector {

    min-height: 36px !important;
}

.ant-select.ant-tree-select.input-small .ant-select-selection-overflow {
    scrollbar-width: thin;
}

.ant-select .ant-select-selector {
    border-radius: 3px;
}

.ant-steps.ant-steps-horizontal {
    margin-bottom: 30px;
}

.ant-upload-text {
    color: #181C32;
    font-size: 14px;
}

.ant-upload-text span {
    display: block;
    text-align: center;
    color: #7A7A7A;
    font-size: 14px;
}

.ant-layout-sider {
    display: flex;
    flex-direction: column;
}

.ant-layout-sider-children {
    order: 1
}

.ant-layout-sider-trigger {
    position: static !important;
    order: 0;
    background-color: rgb(250, 250, 250) !important;
}

.ant-upload-wrapper .ant-upload-drag {
    background-color: #fff;
    border: 1px dashed #E2C00D;
}

.ant-layout-footer {
    background-color: #fff;
}

.ant-select-selection-item {
    border-radius: 3px;
}

.ant-select-selection-overflow {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;
}

.ant-menu-submenu .ant-menu.ant-menu-sub.ant-menu-inline {
    background: transparent;
}

.ant-menu-submenu-title {
    /* height: 25px; */
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.ant-menu-inline .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0;
}

.ant-menu .ant-menu-submenu-arrow::before,
.ant-menu .ant-menu-submenu-arrow::after {
    height: 1px;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    text-align: left;
}

.ant-modal-header .ant-modal-title {
    font-size: 24px;
    font-weight: 600;
    /* margin-bottom: 30px; */
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
    min-height: 33px;
}

.editable-row:hover .editable-cell-value-wrap {
    /* padding: 4px 11px; */
    /* box-shadow: 0 0 1px 1px rgba(0,0,0,.3); */
    /* border: 1px solid #d9d9d9; */
    border-radius: 2px;
}

td.ant-table-cell.ant-table-cell-ellipsis {
    padding: 0 !important;
}

td.ant-table-cell.newcolumn {
    border-color: transparent !important;

}

td.ant-table-cell.newcolumn.ant-table-cell-row-hover {
    background: #fff !important;

}

.ant-table-header .react-resizable {
    position: relative;
    background-clip: padding-box;
}

.ant-table-header .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 100;
    width: 10px;
    height: 100%;
    cursor: col-resize !important;
}

.ant-modal .ant-modal-content {
    padding: 20px 35px;
}

.ant-modal {
    .ant-modal-header {
        margin-bottom: 20px;
    }

    .ant-modal-footer {
        margin-top: 20px;

        .ant-btn {
            font-size: 16px;
            height: 40px;
            padding: 6px 15px;
            border-radius: 4px;
        }
    }
}

.ant-btn-primary.ant-btn-lg{
    height: 50px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder{
    padding: 0 18px;
}
th.ant-table-cell{
    padding: 10px 16px;
}
.ant-table-wrapper .ant-table-cell-ellipsis  .ant-table-column-title{
    overflow: visible;
}