.alert{
    display: flex;
    align-items: center;
    padding: 18px 17px 18px;
    margin-bottom: 24px;
    font-size: 16px;
    color: #181C32;
}
.alert.vertical{
    flex-direction: column;
    
}
.alert.vertical .alert_message{
    width: 300px;
}
.alert_icon{
    margin-right: 23px;
}
.alert_title{
    font-weight: 600;
    margin-right: 5px;
}
.alert_message{

}
.alert_btn{
    margin-left: 100px;
}
.alerttype_error{
    color: #FE0B0B;
}
.alerttype_warning{
    color: #E2C00D;
}
.alerttype_success{
    color: #08C81B;
}