body .ant-layout {
    background-color: #fff;
}

body {
    background-color: #fcfcfc;
    margin: 0;
    font-family: Manrope, Roboto, sans-serif;
}

.logo img {
    max-width: 200px;
    width: 100%;
}

.logo.logo-layout {
    padding: 10px 0;
}
header.ant-layout-header{
    height: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 25px;
}
#root {
    min-height: 100vh;
}