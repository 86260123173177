.newproject-sidebar {
    margin: 0 10px;
}

.newproject-btn {
    background-color: #f9f9f9;
    width: 100%;
    border: 0;
    padding: 8px 16px;
    /* border-radius: 13px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 10px; */
    cursor: pointer;
}

.newproject-btn>span .anticon {
    margin-right: 3px;

    margin-top: 1px;
    display: block;
}

.newproject-btn>span {
    display: flex;
    color: #1777ff;
}
.myProjectsTitle{
    color: rgba(122, 122, 122, 1);
    font-weight: 500;
    font-size: 20px;
    margin-left: 28px;
    margin-top: 35px;
    margin-bottom: 10px;
}
.myProjectsButton{
    color: #4A5EFF;
    background-color: #fff;
    padding: 14px 24px;
    transition: all .3s ease-in-out;
    border: 1px solid #4A5EFF;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    font-family: Manrope, Roboto, sans-serif;
    display: flex;
    align-items: center;
}
.myProjectsButton .myProjectsButton-text{
    margin-right: 20px;
}
.myProjectsButton:hover{
    color: #fff;
    background-color: #4A5EFF;
}