.attention{
    border: 1px dashed #E2C00D;
    border-radius: 3px;
    padding: 16px;
    text-align: center;
    margin: 30px 0;
}
.tac{
    text-align: center;
  }

  .ftac{
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .btn:hover{
    color: #fff!important;
    background-color: #4A5EFF!important;
}
.btn:hover .text{
    color: #fff;
}
.btn .text{
    color: #4A5EFF;
}
.btn-small{
    padding: 3px!important;
}
.btn-mid{
    padding: 4px 12px!important;
}
.btnline{
    border: none;
    color: #4A5EFF;
    text-decoration: underline;
    font-size: 13px;
    box-shadow: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.btn{
    color: #4A5EFF;
    background-color: #fff;
    padding: 12px 24px!important;
    transition: all .3s ease-in-out;
    border: 1px solid #4A5EFF;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    font-family: Manrope, Roboto, sans-serif;
    display: flex;
    align-items: center;
    height: 50px;
}

.stepsBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}
.stepsWrapper{
    margin-top: 30px;
}

.deletemodal_content{
    .content{
        text-align: center;
        font-size: 16px;
        padding: 10px 0 10px;
    }
    .btns{
        margin-top: 20px;
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
}
.integrationstatus{
    display: flex;
    align-items: center;
    gap: 7px
}
.link{
    color: #4A5EFF;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.bluecolumn{
    background-color: rgba(74,123,255, .06) !important;
}
.infoblock{
    background-color: rgb(250 250 250);
    padding: 10px 20px;
    line-height: 1;
    border-radius: 19px;
    margin-bottom: 20px;
}

.select-interval .ant-select-selection-item {
    background-color: transparent !important;
}

.select-interval .ant-select-selection-item-remove {
    display: none !important;
}
